import * as icons from './icons';

import {empty as defaultIcon} from './icons';

export class ExoIcon extends HTMLElement {
  static observedAttributes = [
    'icon',
  ];

  #manageIcon(icon) {
    const iconValue = icons[icon];

    if (!iconValue) {
      console.warn(`No such icon ${icon}`);
    }

    this.innerHTML = iconValue ?? defaultIcon;
    const svg = this.querySelector('svg');

    if (svg) {
      svg.setAttribute('aria-hidden', 'true');
      svg.setAttribute('focusable', 'false');
      svg.setAttribute('fill', 'currentColor');
      svg.style.width = '1em';
      svg.style.height = '1em';
      svg.style.verticalAlign = '-0.125em';
    }
  }

  connectedCallback() {
    if (this.hasAttribute('icon')) {
      const icon = this.getAttribute('icon');
      this.#manageIcon(icon);
    } else {
      console.warn('Must provide an icon attribute');
    }
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (oldValue === newValue) {
      return;
    }

    if (name === 'icon') {
      this.#manageIcon(newValue);
    }
  }
}
